.slanted__button {
	border: 2px solid $yellow;
	color: $yellow;
	display: inline-block;
	text-decoration: none;
	padding: 1rem 2.5rem;
	transition: all 300ms ease;
	background: $black;
	strong {
		font-size: inherit; }
	position: absolute;
	left: 50%;
	top: 20vh;
	transform: skew(15deg) rotate(15deg) translateX(-50%);
	font-size: 2rem;
	width: 80%;
	text-align: center;
	@include tablet {
		width: auto;
		font-size: 3rem;
		left: 50%;
		top: auto;
		bottom: 50%;
		transform: skew(15deg) rotate(15deg); }
	z-index: 10;
	transform-origin: left top;
	@include full {
		left: 80rem; }
	&:hover {
		background: $yellow;
		color: $black; } }
.main__button {
	background: $yellow;
	border: none;
	color: $black;
	padding: 1rem 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: all 300ms ease;
	border: 2px solid $yellow;
	font-size: 2rem;
	&:hover {
		background: $black;
		color: $yellow;
		border-color: $yellow; } }

.secondary__button {
	display: block;
	font-size: 1.6rem;
	padding: 1rem 2rem;
	border: 2px solid white;
	margin: 2rem 1rem;
	cursor: pointer;
	user-select: none;
	transition: all 300ms ease;
	&:hover {
		background: $yellow;
		color: $black;
		border-color: $yellow; } }
