.modal {
	display: none;
	position: fixed;
	z-index: 100;
	background: rgba(black, 0.9);
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	padding: 2rem;
	align-items: center;
	justify-content: center;
	&.active {
		display: flex; }
	.modal__content {
		width: 100%;
		max-width: $desktop-large;
		background: white;
		padding: 4rem 2rem;
		text-align: center;
		max-height: 100%;
		overflow-y: auto;
		h2 {
			font-size: 3rem;
			margin-bottom: 4rem; }
		.main__button {
			margin: 0 auto;
			display: flex;
			width: 25rem;
			&:hover {
				background: white; } }
		.color__list {
			margin-bottom: 4rem;
			.avatar__item {
				display: inline-block;
				.colorpicker {
					width: 4rem;
					height: 4rem;
					border-radius: 50%;
					border: 2px solid $black;
					margin: 1rem;
					cursor: pointer;
					transform-origin: center;
					transition: all 300ms ease;
					@for $i from 1 through 8 {
						&.h#{$i} {
							background: nth( $hair, $i ); } }
					@for $i from 1 through 6 {
						&.s#{$i} {
							background: nth( $skin, $i ); } }
					@for $i from 1 through 13 {
						&.c#{$i} {
							background: nth( $outfit, $i ); } } }
				&.selected {
					.colorpicker {
						transform: scale(1.2); } } } }
		.augen__list {
			@include grid-areas('b1 b2' 'b3 b4' 'b5 b6' 'b7 b8', repeat(2, 1fr), auto auto auto auto, 0);
			@include mobile {
				@include grid-areas('b1 b2 b3 b4' 'b5 b6 b7 b8', repeat(4, 1fr), auto auto, 0); }
			.avatar__item {
				min-height: 15rem;
				.avatar {
					// transform: translateY(-25%)
					svg {
						width: 50%;
						margin: 0 auto;
						transition: all 300ms ease;
						transform-origin: center; } }
				&.selected {
					.avatar {
						svg {
 } } } } }							// transform: scale(1.2)
		.selection__list {
			@include grid-areas('b1 b2 b3 b4' 'b5 b6 b7 b8' 'b9 b10 b11 b12' 'b13 b14 . .', repeat(4, 25%), auto auto auto  auto, 0);
			@include mobile {
				@include grid-areas('b1 b2 b3 b4 b5 b6 b7' 'b8 b9 b10 b11 b12 b13 b14', repeat(7, calc(100% / 7)), auto auto, 0); } }
		.bart__list {
			@include grid-areas( 'b1 b2 b3' 'b4 b5 b6', repeat(3, 1fr), auto auto );
			@include mobile {
				@include grid-areas( 'b1 b2 b3 b4 b5 b6', repeat(6, 1fr), auto ); } }
		.mund__list {
			@include grid-areas( 'b1 b2' 'b3 b4', repeat(2, 1fr), auto auto );
			@include mobile {
				@include grid-areas( 'b1 b2 b3 b4', repeat(4, 1fr), auto ); }
			.avatar__item {
				min-height: 15rem;
				.avatar {
					// transform: translateY(-25%)
					svg {
						width: 50%;
						margin: 0 auto;
						transition: all 300ms ease;
						transform-origin: center; } }
				&.selected {
					.avatar {
						svg {
							transform: scale(1.2); } } } } }
		.selection__list, .augen__list {
			.avatar__item {
				@for $i from 1 through 14 {
					&:nth-child(#{$i}) {
						grid-area: b#{$i}; } }
				#Shape {
					display: none; }
				&.selected {
					#Shape {
						display: block;
						#shapecolor {
							display: none; } } } } } } }
