.dashboard {
	.dashboard__container {
		h3 {
			font-size: 2rem;
			margin-bottom: 1rem; }
		width: 100%;
		// max-width: $full
		padding: 0 2rem 2rem;
		@include tablet {
			padding: 0 4rem 2rem; }
		margin: 0 auto;

		@include tablet {
			@include grid-areas('rangliste haus' 'lose haus' 'wochenpreis haus', 40vw auto, 35rem 20rem auto, 2rem); }
		@include desktop {
			@include grid-areas('rangliste haus' 'lose haus' 'wochenpreis haus', 55rem auto, 35rem 20rem auto, 2rem); }
		.dashboard__haus {
			grid-area: haus;
			margin-bottom: 2rem;
			.avatar {
				width: 20rem;
				margin: 0 auto;
				h3 {
					font-size: 4rem;
					line-height: 1; } }
			@include tablet {
				margin-bottom: 0; }
			.current__ranking {
				text-align: center;
				margin-bottom: 2rem;
				strong {
					font-size: 2rem; }
				h3 {
					color: $yellow;
					font-size: 10rem;
					line-height: 1; } }
			.mein__haus {
				img {
					margin: 0 auto;
					width: 100%;
					max-width: 35rem; } } }

		.dashboard__lose {
			grid-area: lose;
			padding: 2rem;
			border: 1px solid $yellow;
			margin-bottom: 2rem;
			@include tablet {
				margin-bottom: 0rem;
				max-width: 40vw;
				width: 55rem; }
			.star {
				width: 12rem;
				height: 12rem;
				background: url('../img/icon-star.svg') center no-repeat;
				background-size: contain;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 1.4rem; }
			.splide__track {
				&:before {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					width: 6rem;
					height: 100%;
					background: $black;
					display: block;
					z-index: 1; }
				&:after {
					content: "";
					position: absolute;
					right: 0;
					top: 0;
					width: 6rem;
					height: 100%;
					background: $black;
					display: block;
					z-index: 1; } } }
		.dashboard__wochenpreis {
			grid-area: wochenpreis;
			@include tablet {
				max-width: 40vw;
				width: 55rem; }
			background: $yellow;
			display: flex;
			justify-content: space-between;

			.text__area {
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: 2rem;
				* {
					color: $black; } }
			.image__area {
				background: white; } } } }

.modis__rangliste {
	grid-area: rangliste;
	border: 1px solid $grey;
	margin-bottom: 2rem;
	position: relative;
	max-height: 35rem;
	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 50px;
		background: linear-gradient(rgba($black, 0), $black); }
	.rangliste__container {
		padding: 2rem;
		max-height: 33rem;
		overflow: auto;
		position: relative;
		transition: all 300ms ease; }
	img {
		position: absolute;
		left: 50%;
		bottom: 2rem;
		transform: translateX(-50%);
		width: 3rem;
		cursor: pointer;
		z-index: 10; }

	@include tablet {
		margin-bottom: 0rem;
		max-width: 40vw;
		width: 55rem; }
	ol {
		margin-bottom: 5rem;
		li {
			margin-left: 2rem;
			margin-bottom: 0.5rem;
			strong {
				color: $yellow; } } } }
