.loginscreen {
	position: relative;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	padding-top: 15rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2rem;
	form {
		width: 100%;
		max-width: 50rem;
		h1 {
			font-size: 4rem;
			margin-bottom: 2rem;
			color: white; }
		label {
			display: block;
			width: 100%;
			margin-bottom: 1rem;
			color: white;
			font-size: 1.8rem;
			input {
				display: block;
				width: 100%;
				font-size: 1.6rem;
				padding: 1rem;
				margin-top: 1rem; } }
		button {
			width: 100%;
			margin-top: 2rem; } }

	.haus__login {
		position: absolute;
		bottom: -5rem;
		right: -5rem;
		width: 40rem; } }
