.home__logo {
	position: absolute;
	right: 3rem;
	top: 3rem;
	width: 15rem;
	z-index: 10;
	@include tablet {
		right: 4rem;
		top: 4rem;
		width: 20rem; } }
.homescreen {
	width: 100vw;
	height: 100vh;
	position: relative;
	.homescreen__image {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		max-width: 160rem;
		z-index: 0;
		&.mobile {
			@include mobile {
				display: none; } }
		&.desktop {
			display: none;
			@include mobile {
				display: block; } } }

	img {
		display: block;
		max-width: 100%; } }
