.losfee {
	.content__container {
		text-align: center;
		h3 {
			font-size: 2rem;
			padding: 1rem 2rem;
			display: inline-flex;
			background: white;
			color: orangered;
			margin-bottom: 2rem; } }

	.main__button {
		text-decoration: none;
		max-width: 25rem;
		margin: 0 auto; } }
