nav {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: $black;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2rem;
	background: linear-gradient($black, rgba($black, 0));
	z-index: 10;
	@include desktop {
		padding: 4rem;
		@include grid-areas('logo items', 20rem 1fr, auto, 2rem);
		align-items: flex-start; }
	>a {
		grid-area: logo;
		position: relative;
		z-index: 10;
		img {
			display: block;
			max-width: 100%;
			width: 12rem;
			@include desktop {
				width: 20rem; } } }
	.burger__nav {
		grid-area: burger;
		width: 4rem;
		height: 4rem;
		position: relative;
		cursor: pointer;
		z-index: 10;
		@include desktop {
			display: none; }
		span {
			position: absolute;
			@include transform-center;
			width: 3rem;
			height: 3px;
			background: $yellow;
			transition: all 300ms ease;
			transform-origin: center;
			&:before,
			&:after {
				transform-origin: center;
				transition: all 300ms ease;
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				background: inherit; }
			&:before {
				transform: translateY(-300%); }
			&:after {
				transform: translateY(300%); } } }
	.nav__items {
		display: none;
		@include desktop {
			display: flex; }

		grid-area: items;
		align-items: center;
		justify-content: flex-end;
		a {
			padding: 1rem;
			margin-left: 1rem;
			color: white;
			text-decoration: none;
			font-weight: 700;
			font-size: 1.8rem;
			transition: all 300ms ease;
			&:hover {
				color: $yellow; }
			&.logout {
				border: 2px solid white;
				padding: 1rem 2rem;
				margin-left: 2rem;
				&:hover {
					background: $yellow;
					color: $black;
					border-color: $yellow; } } }
		.nav__item.active {
			a {
				color: $yellow; } } }
	&.active {
		.burger__nav {
			span {
				&:before,
				&:after {
					transform: translateY(0); } } }
		.nav__items {
			display: block;
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100vh;
			background: $black;
			padding: 10rem 2rem 2rem 2rem;
			.nav__item {
				display: block;
				margin-bottom: 2rem;
				a {
					margin: 0; }
				.logout {
					margin-top: 2rem;
					display: flex;
					justify-content: center; } } }


		@include desktop {
			display: none; } } }
