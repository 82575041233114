// Media Querys
@mixin small {
	@media (min-width: $small) {
		@content; } }
@mixin mobile {
	@media (min-width: $mobile) {
		@content; } }
@mixin tablet {
	@media (min-width: $tablet) {
		@content; } }
@mixin desktop {
	@media (min-width: $desktop) {
		@content; } }
@mixin full {
	@media (min-width: $full) {
		@content; } }

// Layouts
@mixin flex-column {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column; }

@mixin transform-center {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%); }

@mixin absolute-full {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%; }

@mixin grid-areas($areas, $columns, $rows, $gap: 0) {
	display: grid;
	grid-gap: $gap;
	grid-template-areas: $areas;
	grid-template-columns: $columns;
	grid-template-rows: $rows; }



@mixin retina {
	@media (-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
		@content; } }
