.setup {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	form {
		fieldset {
			padding: 2rem;
			border: none;
			text-align: center;
			display: none;
			&.active {
				display: block; }
			h2 {
				font-size: 3rem;
				display: block;
				margin-bottom: 4rem; }
			h3 {
				color: $yellow;
				font-size: 2rem;
				margin: 2rem 0; }

			.main__button {
				display: inline-flex;
				margin: 2rem auto;
				min-width: 25rem; }
			.edit__list {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				.secondary__button {
					margin: 1rem; } }
			.house__list {
				display: flex;
				justify-content: center;
				.radio__group {
					flex: 1 0 30%;
					cursor: pointer;
					label {
						cursor: pointer;
						padding: 2rem;
						display: block;
						transition: all 300ms ease;
						border: 1px solid transparent;
						margin: 2rem; }
					img {
						width: 15rem; }
					input {
						display: none;
						&:checked {
							&~label {
								border: 1px solid $yellow; } } } } } } } }
.splide__arrow {
	transition: opacity 300ms ease;
	&--next {
		background: url('../img/icon-next.svg') center no-repeat;
		background-size: contain; }
	&--prev {
		background: url('../img/icon-prev.svg') center no-repeat;
		background-size: contain; }
	svg {
		display: none; } }
