.duell {
	.duell__container {
		width: 100%;
		padding: 0 2rem 2rem;
		flex-direction: column;
		@include tablet {
			flex-direction: row; }
		display: flex;
		flex-wrap: wrap;
		padding: 0 4rem 2rem;
		.dashboard__enemy {
			flex: 1 0 50%;
			order: 2;
			border-top: 1px solid $grey;
			padding-top: 2rem;
			@include tablet {
				padding-top: 0;
				border-top: none;
				border-left: 1px solid $grey; }
			.avatar {
				width: 18rem;
				margin: 0 auto; }
			.current__rank {
				display: block;
				text-align: center;
				margin-top: 2rem;
				span {
					color: $yellow;
					font-size: 200%;
					font-weight: bold; } }
			.mein__haus {
				img {
					margin: 0 auto;
					width: 100%;
					max-width: 34rem; } } }
		.dashboard__haus {
			flex: 1 0 50%;
			padding-bottom: 2rem;
			@include tablet {
				padding-bottom: 0; }
			.avatar {
				width: 18rem;
				margin: 0 auto; }
			.mein__haus {
				img {
					margin: 0 auto;
					width: 100%;
					max-width: 34rem; } }
			.current__rank {
				display: block;
				text-align: center;
				margin-top: 2rem;
				span {
					color: $yellow;
					font-size: 200%;
					font-weight: bold; } } }

		.modis__rangliste {
			width: 100%;
			@include tablet {
				max-width: 35rem; }
			max-height: 70rem;
			.rangliste__top {
				padding: 2rem;

				h3 {
					font-size: 2rem;
					margin-bottom: 2rem; }
				input {
					color: $black;
					padding: 0.5rem 1rem;
					font-size: 1.6rem;
					display: block;
					width: 100%;
					border: 1px solid $black;
					&:focus {
						outline: none;
						border: 1px solid $yellow; } } }
			.rangliste__container {
				max-height: 56rem;
				padding-top: 0;
				li {
					visibility: visible;
					cursor: pointer;
					a {
						text-decoration: none; }
					&.isme {
						cursor: initial; }
					&.hidden {
						margin: 0;
						visibility: hidden;
						height: 0;
						overflow: hidden; } } } } } }
