.city {
	.user__info {
		display: flex;
		align-items: center;
		margin-top: 2rem;
		.avatar {
			width: 12rem;
			margin-right: 2rem; } }
	canvas {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%; } }
