@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/poppins-regular.eot'), url('../fonts/poppins-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/poppins-regular.woff2') format('woff2'), url('../fonts/poppins-regular.woff') format('woff'), url('../fonts/poppins-regular.ttf') format('truetype'), url('../fonts/poppins-regular.svg#Poppins') format('svg'); }



@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/poppins-700.eot'), url('../fonts/poppins-700.eot?#iefix') format('embedded-opentype'),url('../fonts/poppins-700.woff2') format('woff2'), url('../fonts/poppins-700.woff') format('woff'), url('../fonts/poppins-700.ttf') format('truetype'),  url('../fonts/poppins-700.svg#Poppins') format('svg'); }
